export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "We Become What We Behold",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://webecomewhatwebehold.io/game/webecomewhatwebehold/",
    domain: "webecomewhatwebehold.fun",
    gaId: "G-X1SKMHWMR8",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
